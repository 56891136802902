import React, { useState, useEffect } from 'react';

export const Politic = () => {
  const [htmlString, setHtmlString] = useState('');

  useEffect(() => {
    fetch('https://shop-hm.yakovlev.studio/documents/privacy-policy')
      .then((res) => res.json())
      .then((res) => {
        setHtmlString(res);
        console.log('res:', res);
      });
  }, []);

  return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
  
};

export default Politic;
