import { Button, Image, StyleSheet, Text, View } from 'react-native';
import Logo from './logo-hm.svg';
import Politic from './agreements';

function App() {
  return (
    <View style={styles.app}>
      <View style={styles.header}>
        <Image
          accessibilityLabel="React logo"
          source={{ uri: Logo }}
          resizeMode="contain"
          style={styles.logo}
        />
        <Text style={styles.title}>Хмельная миля</Text>
      </View>
      <Politic />
    </View>
  );
}

const styles = StyleSheet.create({
  app: {
    marginHorizontal: 'auto',
    maxWidth: 500,
  },
  logo: {
    height: 60,
    width: 60,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'center',
    padding: 10,
    paddingBottom: 0,
    // backgroundColor: 'pink',
    alignItems: 'center',
  },
  title: {
    paddingLeft: 20,
    fontWeight: 'bold',
    fontSize: '1.5rem',
    // marginVertical: '1em',

    textAlign: 'center',
  },
});

export default App;
